import { Section } from "@atoms/text";
import { useShortcuts } from "@features/shortcuts";
import { Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { ErrorBoundary } from "@views/error-boundary";
import {
  Fragment,
  ReactNode,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { atom, useRecoilState } from "recoil";
import { twMerge } from "tailwind-merge";

const ModalsCountState = atom({
  key: "ModalsState",
  default: 0,
});

const visibleModals = { value: 0 };

export const Modal = (props: {
  open?: boolean;
  onClose?: () => void;
  children?: React.ReactNode;
  closable?: boolean;
  closableWithWarning?: boolean;
  className?: string;
  style?: any;
  positioned?: boolean;
}) => {
  const [open, setOpen] = useState(false);
  const [modalsCountState, setModalsCountState] =
    useRecoilState(ModalsCountState);
  const [level, setLevel] = useState(0);
  const openStatus = useRef<boolean>(false);

  const handleOnCloseProps = useCallback(() => {
    if (props.closableWithWarning) {
      if (window.confirm("Are you sure you want to close this modal?")) {
        props.onClose && props.onClose();
      }
    } else {
      props.onClose && props.onClose();
    }
  }, [props.onClose, props.closableWithWarning]);

  const onClose = useCallback(() => {
    openStatus.current = false;
    visibleModals.value += -1;
    setModalsCountState(visibleModals.value);
  }, [setModalsCountState]);

  const onOpen = useCallback(() => {
    openStatus.current = true;
    visibleModals.value += 1;
    setLevel(visibleModals.value);
    setModalsCountState(visibleModals.value);
  }, [setModalsCountState]);

  useEffect(() => {
    if (props.open !== open) {
      setOpen(props.open || false);
      if (props.open) {
        onOpen();
      } else {
        onClose();
      }
    }
  }, [props.open, open, onClose, onOpen]);

  useEffect(() => {
    return () => {
      if (openStatus.current) onClose();
    };
  }, [openStatus, onClose]);

  const zIndex = "z-" + (level + 5) + "0";

  return (
    <Transition.Root show={open} as={Fragment}>
      <div className={"relative " + zIndex}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0 pointer-events-none"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0 pointer-events-none"
        >
          <div
            className={twMerge(
              "fixed inset-0 z-50 bg-opacity-25 dark:bg-opacity-75 transition-opacity " +
                (level === 1 ? "bg-black" : "bg-transparent"),
              open && "opacity-100 ease-out duration-300",
              !open && "opacity-0 pointer-events-none ease-in duration-200"
            )}
          />
        </Transition.Child>

        <div
          className={
            "fixed z-50 inset-0 overflow-y-auto transition-transform " +
            (level !== modalsCountState && open
              ? "-translate-y-6 sm:scale-95 "
              : level !== modalsCountState && !open
              ? "translate-y-6 sm:scale-95 "
              : "")
          }
          onClick={() => handleOnCloseProps()}
        >
          <div
            className={
              "flex items-end justify-center min-h-screen text-center sm:block "
            }
          >
            {
              /* This element is to trick the browser into centering the modal contents. */
              !props.positioned && (
                <span
                  className="hidden sm:inline-block sm:align-middle sm:h-screen"
                  aria-hidden="true"
                >
                  &#8203;
                </span>
              )
            }
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom={
                "opacity-0 pointer-events-none sm:translate-y-0 translate-y-4 sm:scale-95"
              }
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo={
                "opacity-0 pointer-events-none sm:translate-y-0 translate-y-4 sm:scale-95"
              }
            >
              <div
                onClick={(e) => e.stopPropagation()}
                className={
                  "align-bottom bg-white dark:bg-slate-900 px-4 pt-5 pb-4 text-left w-full sm:w-auto overflow-hidden shadow-xl transform transition-all sm:align-middle sm:p-6 " +
                  "relative inline-block rounded-tr-xl rounded-tl-xl sm:rounded-md sm:my-8 w-full sm:w-full sm:max-w-[600px] " +
                  (props.className || "")
                }
                style={props.style || {}}
              >
                {level !== modalsCountState && (
                  <div className="absolute z-50 top-0 left-0 w-full h-full bg-white dark:bg-slate-900 opacity-75" />
                )}
                <div
                  className={
                    "absolute z-50 bg-black transition-all pointer-events-none " +
                    (level !== modalsCountState ? "opacity-25" : "opacity-0")
                  }
                  style={{
                    width: 1000000,
                    height: 1000000,
                    top: -5000,
                    left: -5000,
                  }}
                />
                {props.closable !== false && (
                  <div className="z-20 absolute top-0 right-0 pt-4 pr-4">
                    <button
                      type="button"
                      className="bg-slate-300 dark:bg-slate-600 rounded-full p-1 text-slate-600 dark:text-slate-300 hover:opacity-75 focus:outline-none "
                      onClick={() => handleOnCloseProps()}
                    >
                      <XMarkIcon className="h-5 w-5" aria-hidden="true" />
                    </button>
                    <CloseShortcut onClose={handleOnCloseProps} />
                  </div>
                )}
                <ErrorBoundary>{props.children}</ErrorBoundary>
              </div>
            </Transition.Child>
          </div>
        </div>
      </div>
    </Transition.Root>
  );
};

export const CloseShortcut = (props: { onClose?: () => void }) => {
  useShortcuts(["esc"], () => {
    props.onClose && props.onClose();
  });
  return <></>;
};

export const ModalContent = (props: {
  title?: string | ReactNode;
  text?: string;
  buttons?: ReactNode;
  children?: ReactNode;
  icon?: any;
  theme?: "success" | "danger" | "warning" | "gray";
}) => {
  let color = "indigo";
  if (props.theme === "success") color = "green";
  if (props.theme === "danger") color = "red";
  if (props.theme === "warning") color = "orange";
  if (props.theme === "gray") color = "gray";
  return (
    <>
      <div className="sm:flex sm:items-start">
        {props.icon && (
          <div
            className={`mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-${color}-100 sm:mx-0 sm:h-10 sm:w-10`}
          >
            <props.icon
              className={`h-6 w-6 text-${color}-600`}
              aria-hidden="true"
            />
          </div>
        )}
        <div
          className={
            "mt-3 text-center sm:mt-0 sm:text-left " +
            (props.icon ? "sm:ml-4" : "")
          }
        >
          {props.title && (
            <h3 className="mb-4 -mt-2 text-xl font-semibold leading-6 font-medium text-gray-900 dark:text-white pr-6">
              <Section>{props.title}</Section>
            </h3>
          )}
          <div>
            <p className="text-sm text-gray-500 dark:text-white">
              {props.text || ""}
            </p>
          </div>
        </div>
      </div>
      {props.buttons && (
        <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse text-center sm:text-left">
          {props.buttons}
        </div>
      )}
      {props.children}
    </>
  );
};
