import { AssignmentType } from "@features/assignments/types";
import { atom, atomFamily } from "recoil";
import {
  InboxMessage,
  InboxThread,
  InboxThreadRequestOptions,
  ThreadHistoryItemType,
} from "../types";

export const ThreadsListAtom = atom<{ data: InboxThread[]; total: number }>({
  key: "ThreadsListAtom",
  default: { data: [], total: 0 },
});

export const ThreadAtom = atomFamily<InboxThread | null, string>({
  key: "ThreadAtom",
  default: null,
});

export const InboxThreadsFiltersAtom = atom<InboxThreadRequestOptions>({
  key: "InboxThreadsFiltersAtom",
  effects_UNSTABLE: [
    ({ onSet }) => {
      onSet((newValue) => {
        localStorage.setItem("saved.threads_filters", JSON.stringify(newValue));
      });
    },
  ],
  default: (() => {
    let saved = {};
    try {
      saved = JSON.parse(localStorage.getItem("saved.threads_filters") || "{}");
    } catch (e) {
      // No-op
    }

    saved = {
      content: null,

      id: null,
      customer_id: null,
      contact: null,
      review_groups: null,
      assigned_to_member_id: null,

      max_created_at: null,
      max_updated_at: null,
      min_created_at: null,
      min_updated_at: null,

      only_without_review_groups: false,
      only_without_assignment: false,

      status: 1,

      on_page: 1,
      per_page: 10,

      ...saved,
    };
    return saved as InboxThreadRequestOptions;
  })(),
});

export const InboxThreadMessagesAtom = atomFamily<
  {
    data: InboxMessage[];
    total: number;
  },
  string
>({
  key: "InboxThreadMessagesAtom",
  default: { data: [], total: 0 },
});

export const InboxThreadHistoryItemsAtom = atomFamily<
  {
    data: ThreadHistoryItemType[];
    total: number;
  },
  string
>({
  key: "InboxThreadHistoryItemsAtom",
  default: { data: [], total: 0 },
});

export const InboxThreadHistoryAssignmentAtom = atomFamily<
  AssignmentType[],
  string
>({
  key: "InboxThreadHistoryAssignmentAtom",
  default: [],
});
