import { Button } from "@atoms/button/button";
import { Checkbox } from "@atoms/input/input-checkbox";
import InputDate from "@atoms/input/input-date";
import { InputLabel } from "@atoms/input/input-decoration-label";
import { Info } from "@atoms/text";
import { AuditApiClient } from "@features/audit/api-client/api-client";
import { AuditExportFilters } from "@features/audit/types";
import { useEffect, useState } from "react";
import { toast } from "react-hot-toast";

export default function CustomersExport() {
  const [filter, setFilter] = useState<AuditExportFilters>({
    from_date: new Date(Date.now() - 1000 * 60 * 60 * 24 * 30).toISOString(),
    to_date: new Date().toISOString(),
    with_revisions: false,
  });
  const [loadingExport, setLoadingExport] = useState(false);
  const [areDatesSelected, setAreDatesSelected] = useState(false);
  const [toDateGreaterThanFromDate, setToDateGreaterThanFromDate] =
    useState(true);

  useEffect(() => {
    // Check dates are selected
    setAreDatesSelected(filter.from_date !== "" && filter.to_date !== "");

    // Check that selected to_date >= selected from_date
    if (areDatesSelected) {
      setToDateGreaterThanFromDate(
        new Date(filter.to_date) >= new Date(filter.from_date) ? true : false
      );
    } else {
      setToDateGreaterThanFromDate(true);
    }
  }, [filter, areDatesSelected]);

  return (
    <div className="w-full p-4 border border-gray-200 rounded-sm bg-white">
      <div className="flex flex-row align-bottom relative">
        <InputLabel
          className="mr-2 "
          label="From Date"
          input={
            <InputDate
              value={filter.from_date}
              onChange={(e) => {
                setFilter({ ...filter, from_date: e?.toISOString() || "" });
              }}
            />
          }
        />
        <InputLabel
          className="mr-2"
          label="To Date"
          input={
            <InputDate
              value={filter.to_date}
              onChange={(e) => {
                setFilter({ ...filter, to_date: e?.toISOString() || "" });
              }}
            />
          }
        />
        <InputLabel
          className="mr-2"
          label="With revisions"
          input={
            <Checkbox
              className="ml-2 mt-3"
              value={filter.with_revisions}
              onChange={(e) => {
                setFilter({ ...filter, with_revisions: e });
              }}
            />
          }
        />
        <Button
          loading={loadingExport}
          disabled={!(areDatesSelected && toDateGreaterThanFromDate)}
          className="absolute right-0 self-center"
          onClick={async () => {
            setLoadingExport(true);
            try {
              const data = await AuditApiClient.exportCustomersCSVZipped(
                filter
              );
              const fileURL = URL.createObjectURL(data);
              const link = document.createElement("a");
              link.href = fileURL;
              // prettier-ignore
              const fileName = "audit-export-customers" 
                + (filter.from_date !== "" ? "-from-" + filter.from_date.substring(0, 10).replaceAll("-", "") : "")
                + (filter.to_date !== "" ? "-to-" + filter.to_date.substring(0, 10).replaceAll("-", "") : "")
                + (filter.with_revisions ? "-withRevisions" : "-withoutRevisions")
                + ".zip";
              // prettier-ignore
              link.setAttribute("download", fileName);
              document.body.appendChild(link);
              link.click();
            } catch (e) {
              console.error(e);
              toast.error(
                "An error has occurred while retrieving customers, please try again later"
              );
            }
            setLoadingExport(false);
          }}
        >
          Export
        </Button>
      </div>
      {!areDatesSelected && (
        <Info noColor className="text-red-400">
          {"Both dates must be selected"}
        </Info>
      )}
      {!toDateGreaterThanFromDate && (
        <Info noColor className="text-red-400">
          {"'To Date' must be >= than 'From Date'"}
        </Info>
      )}
    </div>
  );
}
