import countries from "@assets/countries.json";
import Avatar from "@atoms/avatar/avatar";
import { Tag } from "@atoms/badge/tag";
import Card from "@atoms/card";
import { Page } from "@atoms/layout/page";
import { PageBlock } from "@atoms/layout/page-block";
import {
  Base,
  Info,
  InfoSmall,
  Section,
  SectionSmall,
  Title,
} from "@atoms/text";
import Assignments from "@components/assignations";
import { PageLoader } from "@components/page-loader";
import { useHasAccess } from "@features/auth/state/use-access";
import { useCustomer } from "@features/customers/state/use-customer";
import { useCustomerStates } from "@features/customers/state/user-customer-states";
import {
  STATES_COLORS,
  extractCustomerFullName,
} from "@features/customers/utils";
import { formatTime } from "@features/utils/dates";
import {
  BuildingOfficeIcon,
  InformationCircleIcon,
  UserIcon,
} from "@heroicons/react/24/outline";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { DocumentManager } from "./document-manager";
import { Notes } from "./document-manager/notes";
import { Identity } from "./identity";
import { Menu } from "./menu";
import { CustomerNavigation } from "./navigation";
import RelationsGraph from "./relations-graph";
import { CustomerReview } from "./review";
import { CustomerTimeline } from "./timeline";

export const CustomersDetailsPage = () => {
  const { id } = useParams();
  const { customer, loading, refresh } = useCustomer(id || "");
  const { states } = useCustomerStates();
  const hasAccess = useHasAccess();

  useEffect(() => {
    refresh();
  }, [id, refresh]);

  if (!customer?.details?.customer) {
    return (
      <Page>
        <Title className="flex items-center">
          <div className="grow">Details for {id}</div>
        </Title>
        <PageLoader />
      </Page>
    );
  }

  return (
    <Page>
      <Title className="flex items-center">
        <div className="grow">
          Details for{" "}
          {customer ? extractCustomerFullName(customer.details.customer) : ""}
        </div>
        <CustomerNavigation id={id!} />
      </Title>
      <Menu id={id!} />

      {!customer && loading && (
        <div style={{ marginTop: "30vh" }}>
          <PageLoader />
        </div>
      )}

      {customer && (
        <>
          <Section className="mt-4">Customer summary</Section>
          <div className="mt-4 flex flex-row">
            <PageBlock className="grow w-1/2">
              <div className="flex flex-row w-full py-2 items-start">
                {customer.details.customer.customer_type !== "3" && (
                  <Avatar size={14} className="mr-4 shrink-0" />
                )}
                <div className="grow">
                  <Section className="flex flex-row items-center">
                    {customer.details.customer.customer_type !== "3" ? (
                      <UserIcon className="h-5 w-5 inline mr-2 shrink-0" />
                    ) : (
                      <BuildingOfficeIcon className="h-5 w-5 inline mr-2 shrink-0" />
                    )}
                    {extractCustomerFullName(customer.details.customer)}
                  </Section>

                  <Base className="mr-4">
                    <Info>ID</Info> {customer.details.customer?.external_id}
                  </Base>

                  {customer.details.customer?.registration_number && (
                    <Base className="mr-4">
                      <Info>Registration Number</Info>{" "}
                      {customer.details.customer?.registration_number}
                    </Base>
                  )}
                  <br />

                  {customer.details.customer?.date_of_birth && (
                    <Base className="mr-4">
                      <Info>Date of birth</Info>{" "}
                      {(customer.details.customer?.date_of_birth
                        ? new Date(customer.details.customer?.date_of_birth)
                            .toISOString()
                            .split("T")[0]
                        : null) || "Not defined"}
                    </Base>
                  )}
                  {customer.details.customer?.nationality_code && (
                    <Base className="mr-4">
                      <Info>Nationality</Info>{" "}
                      {countries.find(
                        (c) =>
                          c["alpha-3"] ===
                          customer.details.customer?.nationality_code
                      )?.name || "Invalid"}{" "}
                      <Info>
                        ({customer.details.customer?.nationality_code})
                      </Info>
                    </Base>
                  )}
                  {customer.details.customer?.domicile_code && (
                    <Base className="mr-4">
                      <Info>Domicile</Info>{" "}
                      {countries.find(
                        (c) =>
                          c["alpha-3"] ===
                          customer.details.customer?.domicile_code
                      )?.name || "Invalid"}{" "}
                      <Info>({customer.details.customer?.domicile_code})</Info>
                    </Base>
                  )}
                </div>
                <div className="text-right">
                  <div>
                    <Info>Status</Info>
                    <Tag
                      noColor
                      className={
                        "ml-2 text-white capitalize block !px-3 !py-1 text-md bg-" +
                        (STATES_COLORS[
                          customer.details.customer.disabled
                            ? "disabled"
                            : `${customer.details.edd_status.edd_state}`
                        ] || "slate-500")
                      }
                    >
                      {customer.details.customer.disabled
                        ? "Disabled"
                        : states[customer.details.edd_status.edd_state] ||
                          "Unknown"}
                    </Tag>
                  </div>
                  <InfoSmall className="mt-2 block">
                    {customer.details.edd_status.agent_name || "System"} •{" "}
                    {formatTime(customer.details.edd_status.created_at)}
                  </InfoSmall>
                </div>
              </div>
            </PageBlock>
          </div>

          {customer.details.customer.disabled && (
            <Card
              className="bg-slate-400 mt-4"
              text={
                <div className="text-white text-center flex flex-row items-center justify-center">
                  <InformationCircleIcon className="h-5 w-5 inline mr-2" />
                  This customer is disabled, it cannot be modified and is only
                  visible for audit purposes.
                </div>
              }
            />
          )}

          <div className="flex flex-col lg:flex-row lg:space-x-8 justify-between mt-2">
            <div className="shrink-0 lg:w-1/3 lg:max-w-md w-full">
              <Assignments
                type="customer"
                id={customer.details.customer.id}
                groups={customer.details.customer.review_groups || []}
                readonly={!hasAccess("CUSTOMER_UPDATE")}
              />
              <CustomerTimeline
                disabled={customer.details.customer.disabled}
                revisions={customer.edd_history.edd_revisions}
              />
            </div>
            <div className="grow">
              <Section className="mt-4">Algoreg review</Section>
              <CustomerReview customer={customer} />

              <Section className="mt-4">Customer details</Section>
              <PageBlock className="mb-2">
                <Identity customer={customer} edit />
              </PageBlock>

              <Section className="mt-4">Relations</Section>
              <PageBlock className="mb-2">
                <div className="-m-4">
                  <RelationsGraph customer={customer} />
                </div>
                <div className="mt-2" />
              </PageBlock>

              <Section className="mt-4">Documents and notes</Section>
              <PageBlock className="mb-2">
                <Notes customer={customer} />
                <hr className="my-4 -mx-4" />
                <SectionSmall className="mt-4">Documents</SectionSmall>
                <div className="mt-2" />
                <DocumentManager customer={customer} />
              </PageBlock>
            </div>
          </div>
        </>
      )}
    </Page>
  );
};
