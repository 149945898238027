import { atom } from "recoil";

export enum LoginStep {
  NotLoggedIn,
  TwoFA,
  LoggedIn,
}

export type AgentType = {
  id: number;
  name: string;
  email_login: string;
  avatar: string;
  client_code: string;
  permissions: string[];

  clients: {
    agent_id: number;
    id: number;
    label: string;
    code: string;
    active: boolean;
    review_groups: string[] | null;
  }[];
};

export type AuthType = {
  loginStep: LoginStep;
  isLoggedIn: boolean;
  authorization: string;
  authorizationRefresh: string;
  currentClient: number;
  agent: AgentType | null;
};

export const AuthState = atom<AuthType>({
  key: "AuthState",
  default: (() => {
    try {
      return {
        isLoggedIn: false,
        loginStep: LoginStep.NotLoggedIn,
        authorization: JSON.parse(
          localStorage.getItem("agent.access_token") || "null"
        ),
        authorizationRefresh: JSON.parse(
          localStorage.getItem("agent.refresh_token") || "null"
        ),
        currentClient: JSON.parse(
          localStorage.getItem("agent.current_client") || "null"
        ),
        agent: null,
      };
    } catch (e) {
      return {
        isLoggedIn: false,
        loginStep: LoginStep.NotLoggedIn,
        authorization: null,
        authorizationRefresh: null,
        currentClient: null,
        agent: null,
      };
    }
  })(),
});
