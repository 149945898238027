import { Button } from "@atoms/button/button";
import { InputLabel } from "@atoms/input/input-decoration-label";
import SelectMultiple from "@atoms/input/input-select-multiple";
import { Input } from "@atoms/input/input-text";
import { Modal, ModalContent } from "@atoms/modal/modal";
import { useReviewGroups } from "@features/agents/state/use-review-groups";
import _ from "lodash";
import { useState } from "react";
import { twMerge } from "tailwind-merge";

type ReviewGroupInputProps = {
  onChange: (newValue: string[]) => void;
  value: string[];
  canCreateReviewGroup?: boolean;
  className?: string;
};

export function ReviewGroupInput({
  onChange,
  value,
  canCreateReviewGroup = true,
  className,
}: ReviewGroupInputProps) {
  const { all: existingReviewGroups } = useReviewGroups();
  const [reviewGroupModalOpen, setReviewGroupModalOpen] =
    useState<boolean>(false);
  const [reviewGroupModalName, setReviewGroupModalName] = useState<string>("");

  return (
    <div className={twMerge("w-full flex flex-row items-center", className)}>
      <Modal
        open={reviewGroupModalOpen}
        onClose={() => setReviewGroupModalOpen(false)}
      >
        <ModalContent title="Create review group">
          <InputLabel
            label="Name"
            input={
              <Input
                autoFocus
                placeholder="GROUP-A"
                value={reviewGroupModalName}
                onChange={(e) =>
                  setReviewGroupModalName(
                    e.target.value
                      ?.replace(/[^a-zA-Z0-9-_]/g, "")
                      .toLocaleUpperCase()
                  )
                }
              />
            }
          />
          <Button
            className="mt-3"
            onClick={() => {
              setReviewGroupModalOpen(false);
              setReviewGroupModalName("");
              onChange([...value, reviewGroupModalName]);
            }}
          >
            Create
          </Button>
        </ModalContent>
      </Modal>
      <div className="grow">
        <SelectMultiple
          value={value}
          onChange={onChange}
          options={_.uniq([...existingReviewGroups, ...value]).map((a) => ({
            value: a,
            label: a,
          }))}
        />
      </div>
      {canCreateReviewGroup && (
        <Button
          className="ml-2"
          theme="outlined"
          onClick={() => {
            //Open group creation modal
            setReviewGroupModalOpen(true);
          }}
        >
          Create review group
        </Button>
      )}
    </div>
  );
}
