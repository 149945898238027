import { AppPermissions } from "@features/access";
import { is2XX, useFetch } from "@features/utils";
import { AgentType } from "../state/store";
import {
  Credential,
  TwoFAChallengeData,
  TwoFALoginResponse,
  TwoFAType,
  TwoFATypes,
} from "../types";

export class AuthApiClient {
  static getAcos = async () => {
    const response = await useFetch(`/web/authorization/allowed_acos`, {
      method: "GET",
    });
    const data = await response.json();
    return ((data as string[]) || []).map(
      (a) => (AppPermissions as any)[a] as string
    );
  };

  static getUser = async () => {
    const response = await useFetch(`/web/agent/get_agent_info`, {
      method: "GET",
      allClients: true,
    });
    const data = await response.json();
    return data as AgentType;
  };

  static refresh = async (token: string) => {
    const response = await useFetch(`/api/v1/token`, {
      method: "POST",
      formData: true,
      credentials: "include",
      allClients: true,
      body: JSON.stringify({
        refresh_token: token,
        grant_type: "refresh_token",
      }),
    });
    const data = await response.json();
    return data as {
      access_token: string;
      refresh_token: string;
      expires_at: number;
    };
  };

  static login = async (email: string, password: string) => {
    const response = await useFetch(`/api/v1/token`, {
      method: "POST",
      formData: true,
      credentials: "include",
      allClients: true,
      body: JSON.stringify({
        username: email,
        password: password,
        grant_type: "password",
      }),
    });
    const data = await response.json();
    return data as {
      access_token?: string;
      refresh_token?: string;
      expires_at?: number;
      credentials?: Credential[];
    };
  };

  static twoFALogin = async (
    email: string,
    password: string,
    type: TwoFAType,
    additionnalData: TwoFAChallengeData
  ) => {
    const response = await useFetch(`/api/v1/token/${TwoFATypes[type].path}`, {
      method: "POST",
      credentials: "include",
      allClients: true,
      body: JSON.stringify({
        username: email,
        password: password,
        grant_type: "password",
        challenge_answer: additionnalData,
      }),
    });
    const data = await response.json();
    return data as {
      access_token?: string;
      refresh_token?: string;
      expires_at?: number;
    };
  };

  static get2FAChallenge = async (type: TwoFAType, email: string) => {
    const response = await useFetch(`/api/v1/2fa/${TwoFATypes[type].path}`, {
      method: "POST",
      formData: true,
      body: JSON.stringify({ email }),
    });
    const data = await response.json();
    if (!is2XX(response.status)) {
      throw new Error("Failed to login through.");
    }
    return data as TwoFALoginResponse;
  };

  static updatePassword = async (
    password: string,
    newPassword: string,
    confirmNewPassword: string
  ) => {
    const response = await useFetch(`/web/authentication/change_password`, {
      method: "POST",
      allClients: true,
      body: JSON.stringify({
        old_password: password,
        new_password: newPassword,
        confirm_password: confirmNewPassword,
      }),
      formData: true,
    });
    const data = (await response.text()) ? await response.json() : {};
    return data as {
      errors?: { message: string; code: string };
    };
  };

  static logout = async () => {
    await useFetch(`/api/v1/logout`, {
      method: "POST",
      allClients: true,
    });
  };
}
