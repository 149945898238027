import { RestSearchRequest } from "@components/search-bar/utils/types";
import { CustomerTransactionsAggregate } from "@features/kyt/types";
import { atom, atomFamily } from "recoil";
import {
  CachedCustomerType,
  ChildParentCustomerRelation,
  CustomerAllDetailType,
} from "../types";

export const CustomerReviewOnboardingAtom = atomFamily<
  { loading: boolean },
  string
>({
  key: "CustomerReviewOnboardingAtom",
  default: () => ({
    loading: true,
  }),
});

// This atom store customers for auto completes searches and stores all known customers during a session
export const CachedCustomersAtom = atom<CachedCustomerType[]>({
  key: "CachedCustomersAtom",
  default: [],
});

export const CustomerAtom = atomFamily<CustomerAllDetailType | null, string>({
  key: "CustomerAtom",
  default: () => null,
});
export const CustomerTransactionsAggregatesAtom = atomFamily<
  CustomerTransactionsAggregate | null,
  string
>({
  key: "CustomerTransactionsAggregatesAtom",
  default: () => null,
});

export const CustomerRelationsAtom = atomFamily<
  { relations: ChildParentCustomerRelation[]; known: string[] },
  string
>({
  key: "CustomerRelationsAtom",
  default: () => ({
    relations: [],
    known: [],
  }),
});

export const CustomersStatesAtom = atom<{ [key: number]: string }>({
  key: "CustomersStatesAtom",
  default: {},
});

export const CustomersRisksAtom = atom<{ [key: number]: string }>({
  key: "CustomersRisksAtom",
  default: {},
});

export const CustomersQueryInputAtom = atom<RestSearchRequest>({
  key: "CustomersQueryInputAtom",
  default: {
    query: [],
    options: {
      limit: 10,
      offset: 0,
      order_by: "NAME",
      order_way: "ASC",
    },
  },
});

export const CustomerTableViewAtom = atom<string>({
  key: "CustomerTableViewAtom",
  // Get / Save from localstorage
  default: localStorage.getItem("saved.customer_table_view") || "",
  effects_UNSTABLE: [
    ({ onSet }) => {
      onSet((newValue) => {
        localStorage.setItem("saved.customer_table_view", newValue);
      });
    },
  ],
});
