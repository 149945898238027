import { Button } from "@atoms/button/button";
import { Info, SectionSmall } from "@atoms/text";
import { UpdateCustomersRequest } from "@features/customers/types";
import { ROUTES } from "@features/routes";
import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import { Link } from "react-router-dom";

export default function Namesearch({ form }: { form: UpdateCustomersRequest }) {
  return (
    <div className="mt-6">
      <SectionSmall className="mt-6">Name Search</SectionSmall>
      <Info>Run a name search with this customer identity (new page).</Info>
      <br />
      <Link
        target="_blank"
        to={
          ROUTES.NameSearch +
          "?entity_type=" +
          encodeURIComponent(form.customers[0].account_type === "F" ? 2 : 1) +
          "&company_input_data.company_name=" +
          encodeURIComponent(form.customers[0].company_name) +
          "&person_input_data.first_name=" +
          encodeURIComponent(form.customers[0].first_name) +
          "&person_input_data.last_name=" +
          encodeURIComponent(form.customers[0].last_name) +
          "&person_input_data.date_of_birth=" +
          encodeURIComponent(form.customers[0].date_of_birth || "")
        }
      >
        <Button theme="secondary" className="mt-2">
          <MagnifyingGlassIcon className="w-5 inline mr-2"></MagnifyingGlassIcon>
          Name search
        </Button>
      </Link>
    </div>
  );
}
