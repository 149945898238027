import { Tag } from "@atoms/badge/tag";
import { GetStartedCard } from "@atoms/card/get-started";
import { Page } from "@atoms/layout/page";
import Link from "@atoms/link";
import { Base, BaseSmall, Info, Section, Title } from "@atoms/text";
import { useAuth } from "@features/auth/state/hooks";
import { useBilling } from "@features/billing/use-billing";

export const BillingPage = () => {
  const {
    plan,
    isChatEnabled,
    isOnboardingEnabled,
    isPressEnabled,
    isRiskEnabled,
    isScanEnabled,
    isKYTEnabled,
  } = useBilling();
  const { clientId, user } = useAuth();

  return (
    <Page>
      <Title>Billing and plans</Title>
      <div className="mt-4" />
      <Section>Need more ?</Section>
      <GetStartedCard
        title="Contact us"
        show
        text={
          <BaseSmall>
            Reach us using the form available at{" "}
            <Link href="https://www.algoreg.com/about-us" target="BLANK">
              https://www.algoreg.com/about-us
            </Link>{" "}
            or by email at{" "}
            <Link href="mailto:support@algoreg.com">support@algoreg.com</Link>{" "}
            by providing your user and client ids ({clientId}-
            {user?.email_login})
          </BaseSmall>
        }
      />

      <div className="mt-8" />
      <Section>All products and options</Section>

      <div
        className="columns-1 md:columns-2 lg:columns-3 gap-4"
        style={{ maxWidth: 1200 }}
      >
        <ProductTile
          title="Go!Risk"
          description="Go!Risk provides a fully customizable risk assessment tool. With ongoing due diligence and case management capabilities, we help you stay compliant with AML regulations."
          isActive={isRiskEnabled}
        />

        <ProductTile
          title="Go!Scan"
          description="Algoreg's watchlist screening provides daily scanning against more than 10,000 global watchlists. Our advanced false positive filtering drastically reduces operational impacts caused by false alerts."
          isActive={isScanEnabled}
        >
          <BaseSmall className="mt-2 block">
            {(plan.scan_available_lists.includes("ame") ||
              plan.scan_available_lists.includes("soc")) && (
              <>
                •{" "}
                <ActiveTag
                  isActive={plan.scan_available_lists.includes("watchlist")}
                />{" "}
                Sanction and PEP lists
                <br />
                {plan.scan_available_lists.includes("soc") && (
                  <>
                    •{" "}
                    <ActiveTag
                      isActive={plan.scan_available_lists.includes("soc")}
                    />{" "}
                    State Owned Companies (SOC) lists
                    <br />
                  </>
                )}
                {plan.scan_available_lists.includes("ame") && (
                  <>
                    •{" "}
                    <ActiveTag
                      isActive={plan.scan_available_lists.includes("ame")}
                    />{" "}
                    Adverse Media Entities (AME) lists
                    <br />
                  </>
                )}
              </>
            )}
            {plan.scan_factiva_enabled && (
              <>
                • <ActiveTag isActive={plan.scan_factiva_enabled} /> Access to
                historical newspaper articles
                <br />
              </>
            )}
          </BaseSmall>
        </ProductTile>

        <ProductTile
          title="Go!Vid"
          description="Easy to integrate and highly customizable, Go!Vid allows you to tailor scenarios to your specific needs. Beside international ID and face verification, we support non-identity related scenarios, making us versatile for various use cases."
          isActive={isOnboardingEnabled}
        />

        <ProductTile
          title="Go!Chat"
          description="Exchange with your customers to retrieve additional information and documents."
          isActive={isChatEnabled}
        />

        <ProductTile
          title="Go!Press"
          description="Weekly press review of your customers to establish a bad and good press score."
          isActive={isPressEnabled}
        />

        <ProductTile
          title="Go!KYT"
          description="Enable transaction monitoring and detect suspicious schemes post-hoc or in real-time."
          isActive={isKYTEnabled}
        />
      </div>
    </Page>
  );
};

export const ActiveTag = ({
  isActive,
  className,
}: {
  isActive: boolean;
  className?: string;
}) => {
  return (
    <>
      {isActive && (
        <Tag
          className={
            "bg-green-500 text-white flex-row items-center justify-center mb-1 " +
            className
          }
          noColor
        >
          Active
        </Tag>
      )}
      {!isActive && (
        <Tag
          className={"flex-row items-center justify-center mb-1 " + className}
        >
          Inactive
        </Tag>
      )}
    </>
  );
};

export const ProductTile = ({
  title,
  description,
  isActive,
  children,
}: {
  title: string;
  description: string;
  isActive: boolean;
  children?: React.ReactNode;
}) => {
  return (
    <div className="items-center space-x-2 mb-4 inline-flex">
      <div className="max-w-md p-4 border border-gray-200 rounded-sm bg-white">
        <ActiveTag isActive={isActive} className="float-right" />
        <Base className="block mb-2">{title}</Base>
        <Info className="block">{description}</Info>
        {children}
      </div>
    </div>
  );
};
