import { LoadingState, useGlobalEffect } from "@features/utils";
import _ from "lodash";
import { useRecoilCallback, useRecoilState, useRecoilValue } from "recoil";
import { CustomersApiClient } from "../api-client/api-client";
import { CachedCustomerType } from "../types";
import { CachedCustomersAtom } from "./store";

const useAddToCache = () =>
  useRecoilCallback(({ set }) => async (customers: CachedCustomerType[]) => {
    set(CachedCustomersAtom, (oldCustomers) => {
      return _.uniqBy([...oldCustomers, ...customers], "customer_id");
    });
  });

let currentlyCacheLoading: string[] = [];

export const useCustomersCache = (ids: string[]) => {
  const [loading, setLoading] = useRecoilState(
    LoadingState("useCustomersCache")
  );
  const customers = useRecoilValue(CachedCustomersAtom);

  const addToCache = useAddToCache();

  useGlobalEffect(
    "useCustomersCache",
    () => {
      (async () => {
        setLoading(true);
        await Promise.all(
          ids
            .filter(
              (a) =>
                !customers.find((b) => b.customer_id === a) &&
                currentlyCacheLoading.includes(a) === false
            )
            .map(async (id) => {
              currentlyCacheLoading.push(id);
              const customer = await CustomersApiClient.getCustomerDetails(id);
              addToCache([
                {
                  ...customer.customer,
                  details: customer,
                  customer_id: customer.customer.id,
                },
              ]);
              currentlyCacheLoading = currentlyCacheLoading.filter(
                (a) => a !== id
              );
            })
        );
        setLoading(false);
      })();
    },
    [ids.join("")]
  );

  return {
    customers: customers.filter((customer) =>
      ids.includes(customer.customer_id)
    ),
    loading,
  };
};
