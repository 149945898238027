import { AuthenticationJSON } from "@passwordless-id/webauthn/dist/esm/types";
export enum TwoFAType {
  WebAuthn = 0,
}

export const TwoFATypes = {
  [TwoFAType.WebAuthn]: {
    label: "WebAuthn",
    value: TwoFAType.WebAuthn,
    path: "webauthn"
  },
};

export type Credential = {
  id: string;
  type: TwoFAType;
};

export type TwoFALoginResponse = {
  challenge: string;
  credential_ids: string[];
};

export type TwoFAChallengeData = AuthenticationJSON
