import { Checkbox } from "@atoms/input/input-checkbox";
import { Info } from "@atoms/text";
import { useBilling } from "@features/billing/use-billing";
import _ from "lodash";
import { useState } from "react";

export const EnableProducts = () => {
  const [loading, setLoading] = useState(false);
  const { plan, toggleProduct } = useBilling();

  return (
    <div className="w-full p-4 border border-gray-200 rounded-sm bg-white">
      <Info>Enable / disable products for this client</Info>

      {["go!risk", "go!scan", "go!vid", "go!kyt", "go!press"].map(
        (product, i) => (
          <div key={i}>
            <br />
            <Checkbox
              disabled={loading}
              label={_.capitalize(product)}
              value={
                plan.products_enabled.includes(product as any) ||
                (product === "go!risk"
                  ? plan.products_enabled.includes("go!score")
                  : false)
              }
              onChange={async (e) => {
                setLoading(true);
                if (product === "go!scan") {
                  await toggleProduct("go!checker" as any, e);
                }
                await toggleProduct(
                  product === "go!risk" ? "go!score" : (product as any),
                  e
                );
                setLoading(false);
              }}
            />
          </div>
        )
      )}
    </div>
  );
};
