import { Button } from "@atoms/button/button";
import { ButtonConfirm } from "@atoms/button/confirm";
import { Modal, ModalContent } from "@atoms/modal/modal";
import { InboxApiClient } from "@features/inbox/api-client/api-client";
import { InboxThreadsFiltersAtom } from "@features/inbox/state/store";
import { useThread } from "@features/inbox/state/use-thread";
import { useThreads } from "@features/inbox/state/use-threads";
import { InboxMessage, InboxMessageCreateInput } from "@features/inbox/types";
import { ROUTES } from "@features/routes";
import { useControlledEffect } from "@features/utils";
import { htmlDecode } from "@features/utils/strings";
import {
  InboxModalAtom,
  InboxModalThreadStateAtom,
} from "@views/client/inbox/modal";
import { useEffect, useRef, useState } from "react";
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import {
  atom,
  useRecoilState,
  useRecoilValue,
  useSetRecoilState,
} from "recoil";
import { MessageFormExtended } from "./parts/message-form-extended";
import { ThreadInit } from "./parts/thread-init";
import { useInboxMailboxes } from "@features/inbox-settings/state/use-inbox-mailboxes";
import { Info } from "@atoms/text";
import { useThreadMessages } from "@features/inbox/state/use-thread-messages";

export const CreateInboxMessageModalAtom = atom<{
  threadId?: string;
  customerId?: string;
  message?: Partial<InboxMessage>;
  open: boolean;
}>({
  key: "CreateInboxMesageModalAtom",
  default: {
    threadId: undefined,
    open: false,
  },
});

export const CreateMessageModal = () => {
  const [modal, setModal] = useRecoilState(CreateInboxMessageModalAtom);
  const [closable, setClosable] = useState(true);
  const [closableWithWarning, setClosableWithWarning] = useState(false);

  return (
    <Modal
      className="min-w-[60vw]"
      open={modal.open}
      onClose={() => {
        setModal({ ...modal, open: false });
      }}
      closable={closable}
      closableWithWarning={closableWithWarning}
    >
      {modal.open && (
        <CreateMessageModalContent
          key={
            (modal.threadId || "") +
            (modal.message?.id || "") +
            (modal.customerId || "")
          }
          cancel={() => {
            setModal({ ...modal, open: false });
          }}
          setClosable={setClosable}
          message={modal.message}
          threadId={modal.threadId}
          customerId={modal.customerId}
          setClosableWithWarning={setClosableWithWarning}
        />
      )}
    </Modal>
  );
};

const CreateMessageModalContent = (props: {
  cancel: () => void;
  setClosable: (closable: boolean) => void;
  setClosableWithWarning: (closable: boolean) => void;
  message?: Partial<InboxMessage>;
  threadId?: string;
  customerId?: string;
}) => {
  const isInboxOpenAsModal = useRecoilValue(InboxModalAtom);
  const setStateSelectedThread = useSetRecoilState(InboxModalThreadStateAtom);
  const setModal = useSetRecoilState(CreateInboxMessageModalAtom);
  const [newThread, setNewThread] = useState({
    tags: [] as string[],
    customers: props.customerId ? [props.customerId] : [],
  });

  const filters = useRecoilValue(InboxThreadsFiltersAtom);
  const { refresh: refreshAllThreads } = useThreads();
  const {
    myMailboxes,
    loading: mbLoading,
    refresh: mbRefresh,
  } = useInboxMailboxes();

  useEffect(() => {
    mbRefresh();
  }, []);

  const { thread: existingThread, refresh } = useThread(props.threadId ?? "");
  const { messages } = useThreadMessages(props.threadId ?? "");

  useEffect(() => {
    props.setClosableWithWarning(false);
  }, []);

  const initialMessage = useRef({
    mailbox_id:
      messages.data.map((a) => a.mailbox_id).filter((a) => a)[0] || "",
    from: props.message?.from || "",
    to: props.message?.to || "",
    cc: props.message?.cc || "",
    bcc: props.message?.bcc || "",
    subject: htmlDecode(props.message?.subject || "") || "",
    priority: props.message?.priority || 3,
    content: htmlDecode(props.message?.content || "") || "",
    attachments:
      props.message?.attachments?.map((a) => ({
        id: a.id,
        name: a.file_name,
        file: "",
      })) || [],
  });
  const [message, setMessage] = useState<Partial<InboxMessageCreateInput>>(
    initialMessage.current
  );
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const save = async ({ draft }: { draft: boolean }) => {
    setLoading(true);

    try {
      if (props.message?.id) {
        await InboxApiClient.updateMessage(props.message.id, {
          ...(message as InboxMessageCreateInput),
          attachments: undefined,
          new_attachments: message.attachments?.filter((a) => !a.id) || [],
          existing_attachments: message.attachments
            ?.map((a) => a.id as string)
            .filter((a) => a),
          delivery: draft ? 1 : 2,
        });
      } else {
        let threadId = props.threadId;
        if (!threadId) {
          const thread = await InboxApiClient.createThread({
            customer_ids: newThread.customers,
            tag_codes: newThread.tags,
            status: 1,
            reminder: {},
          });
          threadId = thread.id;
        }

        await InboxApiClient.createMessage(threadId, {
          ...(message as InboxMessageCreateInput),
          delivery: draft ? 1 : 2,
        });

        if (isInboxOpenAsModal) {
          setStateSelectedThread(threadId);
        } else {
          navigate(ROUTES.Inbox.replace(":id", threadId));
        }
      }

      refreshAllThreads({
        ...filters,
        _silent: true,
      });
      refresh();

      setModal({ open: false });
      toast.success("Message saved");
    } catch (e) {
      toast.error("Error saving message");
    }

    setLoading(false);
  };

  const threadReady =
    !!newThread.customers.length || props.message?.id || props.threadId;

  useControlledEffect(() => {
    props.setClosable(!threadReady);
  }, [threadReady]);

  const readyToSave =
    (!!newThread.customers.length || props.message?.id || props.threadId) &&
    !(
      message === undefined ||
      (message.content?.length ?? 0) === 0 ||
      (message.to?.length ?? 0) === 0 ||
      message.subject?.length === 0
    );

  if (props.threadId && !existingThread) return <></>;

  if (!mbLoading && myMailboxes.length < 1)
    return (
      <Info>You have access to 0 mailboxes. You can't send any email.</Info>
    );

  return (
    <ModalContent title={props.message?.id ? "Edit Message" : "New Message"}>
      {!props.message?.id && !props.threadId && (
        <>
          <ThreadInit
            customers={newThread.customers}
            tags={newThread.tags}
            onChange={({ tags, customers }) => {
              setNewThread({ tags, customers });
            }}
          />
        </>
      )}

      <div
        className={
          (!props.message?.id && !props.threadId ? "mt-6 " : "") +
          (!props.message?.id && !props.threadId && !threadReady
            ? "opacity-25 pointer-events-none"
            : "")
        }
      >
        <MessageFormExtended
          initialMessage={initialMessage.current || {}}
          threadId={props.threadId}
          customers={
            newThread.customers?.length
              ? newThread.customers
              : existingThread?.customers?.map((a) => a.id) ?? []
          }
          onChange={(message) => {
            if (
              (message.attachments?.length ||
                message.content?.length ||
                message.to?.length ||
                message.subject?.length) &&
              (newThread.customers?.length || props.threadId)
            ) {
              props.setClosableWithWarning(true);
            }
            setMessage(message);
          }}
        />

        <ButtonConfirm
          className="mt-6 float-left mr-2"
          theme="outlined"
          confirmMessage="Are you sure you want to cancel? Your changes will not be saved."
          loading={loading}
          onClick={async () => props.cancel()}
        >
          Cancel
        </ButtonConfirm>

        <ButtonConfirm
          confirmTitle="Save Message"
          className="mt-6 float-right"
          theme="primary"
          loading={loading}
          disabled={!readyToSave || mbLoading}
          onClick={async () => save({ draft: false })}
        >
          Send now
        </ButtonConfirm>

        <Button
          className="mt-6 float-right mr-2"
          theme="outlined"
          loading={loading}
          disabled={!readyToSave}
          onClick={async () => save({ draft: true })}
        >
          Save draft
        </Button>
      </div>
    </ModalContent>
  );
};
