import { DropdownButton } from "@atoms/dropdown";
import { useCustomer } from "@features/customers/state/use-customer";
import { PaperAirplaneIcon } from "@heroicons/react/24/outline";
import { InboxModalAtom } from "@views/client/inbox/modal";
import { useSetRecoilState } from "recoil";
import { useHasAccess } from "../../../../features/auth/state/use-access";

export const Menu = ({ id }: { id: string }) => {
  const hasAccess = useHasAccess();
  const { customer } = useCustomer(id || "");

  const openCustomerInbox = useSetRecoilState(InboxModalAtom);

  return (
    <DropdownButton
      options={[
        ...(hasAccess("CHAT")
          ? [
              {
                onClick: () => {
                  openCustomerInbox(customer?.details.customer.id || null);
                },
                icon: (p: any) => <PaperAirplaneIcon {...p} />,
                label: "Customer inbox",
              },
            ]
          : []),
      ]}
      theme="default"
      size="sm"
      className="float-right mt-4"
    >
      Menu
    </DropdownButton>
  );
};
