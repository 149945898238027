import Avatar from "@atoms/avatar/avatar";
import { Tag } from "@atoms/badge/tag";
import { Button } from "@atoms/button/button";
import { Base, InfoSmall, Section } from "@atoms/text";
import { useAgents } from "@features/agents/state/use-agents";
import { useHasAccess } from "@features/auth/state/use-access";
import { KytTransaction, KytTransactionHistoryItem } from "@features/kyt/types";
import { KYT_TRANSACTION_STATES } from "@features/kyt/utils";
import { formatTime } from "@features/utils/dates";
import { CodeBracketIcon } from "@heroicons/react/24/outline";
import _, { find } from "lodash";
import { useRecoilState } from "recoil";
import { TransactionStatusUpdateModalAtom } from "./status-update-modal";
export default function TransactionTimeline({
  transaction,
  revisions,
}: {
  transaction: KytTransaction;
  revisions: KytTransactionHistoryItem[];
}) {
  const hasAccess = useHasAccess();
  const [modal, setModal] = useRecoilState(TransactionStatusUpdateModalAtom);
  const { getAgentForClient } = useAgents();
  const agents = getAgentForClient();

  return (
    <>
      <Section className="mt-4 mb-4">
        Status
        {hasAccess("KYT_MANAGE") && (
          <Button
            disabled={transaction.state === 0}
            onClick={() =>
              setModal({
                ...modal,
                open: true,
                transaction_id: transaction.id,
              })
            }
            className="float-right"
            theme="outlined"
            size="sm"
            shortcut={["u"]}
          >
            Update status
          </Button>
        )}
      </Section>
      <ol className="relative border-l border-gray-200 dark:border-gray-700 ml-3">
        {_.sortBy(revisions, (d) => -new Date(d.created_at).getTime()).map(
          (revision, index) => (
            <li
              key={revision.revision_number}
              className={
                "mb-2 ml-6 transition-opacity " +
                (index === 0 ? "" : " opacity-75 hover:opacity-100 ")
              }
            >
              <span className="flex absolute -left-3 justify-center items-center w-6 h-6 bg-slate-500 rounded-full">
                {!revision.agent_id && (
                  <CodeBracketIcon className="h-4 w-4 text-white" />
                )}
                {!!revision.agent_id && (
                  <Avatar
                    size={6}
                    fallback={
                      find(agents, (a) => a.agent_id === revision.agent_id)
                        ?.name ?? "?"
                    }
                  />
                )}
              </span>
              {index === 0 && (
                <Tag
                  noColor
                  className="bg-blue-500 text-white capitalize block"
                >
                  {KYT_TRANSACTION_STATES[revision.state] || "Unknown"}
                </Tag>
              )}
              {index > 0 && (
                <Tag className="capitalize block">
                  {KYT_TRANSACTION_STATES[revision.state] || "Unknown"}
                </Tag>
              )}
              <Base className="ml-2 whitespace-pre-wrap">
                {revision.comment}
              </Base>
              <InfoSmall className="block mt-1">
                {find(agents, (a) => a.agent_id === revision.agent_id)?.name ||
                  "System"}{" "}
                • {formatTime(revision.created_at)}
              </InfoSmall>
            </li>
          )
        )}
      </ol>
    </>
  );
}
