import { Button } from "@atoms/button/button";
import { InputDecorationIcon } from "@atoms/input/input-decoration-icon";
import { Input } from "@atoms/input/input-text";
import { Title } from "@atoms/text";
import { Table } from "@components/table";
import { useAssignments } from "@features/assignments/state/use-assignment";
import { InboxThreadsFiltersAtom } from "@features/inbox/state/store";
import {
  useRealtimeThreads,
  useThreads,
} from "@features/inbox/state/use-threads";
import { InboxThread } from "@features/inbox/types";
import { ROUTES } from "@features/routes";
import { useControlledEffect } from "@features/utils";
import { PlusIcon, MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import { MinusCircleIcon, PlusCircleIcon } from "@heroicons/react/20/solid";
import { ErrorBoundary } from "@sentry/react";
import _ from "lodash";
import { useContext, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { atom, useRecoilState, useSetRecoilState } from "recoil";
import { CreateInboxMessageModalAtom } from "./components/modals/create-message-modal";
import ThreadCard from "./components/thread-card";
import { ThreadsListFilters } from "./filters";
import ThreadMessages from "./messages-view";
import { InboxModalContext, InboxModalThreadStateAtom } from "./modal";

const ExtendedChatFiltersAtom = atom({
  key: "ExtendedChatFiltersAtom",
  default: false,
});

let interval: any = 0;

export default function InboxPage() {
  const { threads, refresh, loading } = useThreads();
  const [filters, setFilters] = useRecoilState(InboxThreadsFiltersAtom);
  const [extendedFilters, setExtendedFilters] = useRecoilState(
    ExtendedChatFiltersAtom
  );
  useAssignments("thread", threads.data?.map((a) => a.id) || []);
  useRealtimeThreads();

  const navigate = useNavigate();
  let { id: selectedThreadId } = useParams();
  const [stateSelectedThread, setStateSelectedThread] = useRecoilState(
    InboxModalThreadStateAtom
  );
  const { inModal, customerId: modalCustomerId } =
    useContext(InboxModalContext);
  if (inModal) selectedThreadId = stateSelectedThread || "";
  const setCreateModal = useSetRecoilState(CreateInboxMessageModalAtom);

  const openThread = (id: string) => {
    if (inModal) {
      setStateSelectedThread(id);
    } else {
      navigate(ROUTES.Inbox.replace(":id", id));
    }
  };

  //Refresh every minutes
  useEffect(() => {
    clearInterval(interval);
    interval = setInterval(() => {
      refresh({
        _silent: true,
        ...filters,
        ...(modalCustomerId ? { customer_id: modalCustomerId } : {}),
      });
    }, 120000);
    return () => clearInterval(interval);
  }, [refresh, filters, modalCustomerId]);

  useControlledEffect(() => {
    setTimeout(() => {
      refresh({
        ...filters,
        ...(modalCustomerId ? { customer_id: modalCustomerId } : {}),
      });
    }, 1);
  }, [filters, modalCustomerId]);

  return (
    <>
      <div className="flex flex-row h-full">
        <div
          className={
            "shrink-0 w-80 lg:w-96 flex flex-col border-r dark:border-slate-700 "
          }
        >
          {/* // HEADER */}
          <div className="p-4 pb-0">
            <div className="flex flex-row content-between items-center ">
              <Title className="hidden lg:block lg:mr-4">Inbox</Title>
              <InputDecorationIcon
                input={({ className }) => (
                  <Input
                    placeholder="Search"
                    value={filters.content ?? ""}
                    onChange={(e) => {
                      setFilters({
                        ...filters,
                        content: e.target.value || null,
                      });
                    }}
                    className={className}
                  />
                )}
                prefix={(p) => <MagnifyingGlassIcon {...p} />}
                suffix={({ className }) => {
                  className =
                    "absolute m-auto top-0 bottom-0 right-3 cursor-pointer h-5 w-5 hover:text-blue-700 text-blue-600";
                  return extendedFilters ? (
                    <MinusCircleIcon
                      onClick={() => setExtendedFilters(!extendedFilters)}
                      className={className}
                    />
                  ) : (
                    <PlusCircleIcon
                      onClick={() => setExtendedFilters(!extendedFilters)}
                      className={className}
                    />
                  );
                }}
                className="mr-4 grow"
              />

              <Button
                theme="primary"
                onClick={() =>
                  setCreateModal({
                    open: true,
                    customerId: modalCustomerId,
                  })
                }
              >
                <PlusIcon className="h-4 w-4 inline mr-2 -ml-1 shrink-0" />
                New
              </Button>
            </div>

            {/* // FILTERS */}
            <ThreadsListFilters
              extended={extendedFilters}
              value={filters}
              onChange={(params) => {
                setFilters({
                  ...filters,
                  ..._.omit(
                    params,
                    "order_by",
                    "order_way",
                    "per_page",
                    "on_page"
                  ),
                  on_page: 1,
                });
              }}
            />
          </div>

          <Table
            className="-mt-px grow h-full w-full "
            tableClassName="!w-full"
            cellClassName={(thread: InboxThread) =>
              "!items-start !p-0 " +
              (selectedThreadId === thread.id
                ? "bg-blue-100 dark:bg-blue-800 border-blue-200 dark:border-slate-600"
                : "")
            }
            scrollable
            loading={loading}
            showPagination
            initialPagination={{
              page: filters.on_page,
              perPage: filters.per_page || 10,
            }}
            total={threads.total}
            data={threads.data}
            onClick={(thread: InboxThread) => openThread(thread.id)}
            columns={[
              {
                className: "p-0",
                render: (thread: InboxThread) => <ThreadCard thread={thread} />,
              },
            ]}
            onRequestData={async (pagination) => {
              setFilters({
                ...filters,
                on_page: pagination.page,
                per_page: pagination.perPage,
              });
            }}
          />
        </div>
        <div className="grow h-full w-full overflow-hidden">
          {!!selectedThreadId && selectedThreadId !== "all" && !loading && (
            <ErrorBoundary>
              <ThreadMessages
                key={selectedThreadId}
                threadId={selectedThreadId!}
              />
            </ErrorBoundary>
          )}
        </div>
      </div>
    </>
  );
}
