import { Button } from "@atoms/button/button";
import { Checkbox } from "@atoms/input/input-checkbox";
import { AgentsApiClient } from "@features/agents/api-client/api-client";
import { AgentType } from "@features/agents/types";
import { AlertsFiltersApiClient } from "@features/alerts-filters/api-client/api-client";
import { PostFilteringListType } from "@features/alerts-filters/types";
import { CustomFieldsApiClient } from "@features/custom-fields/api-client/api-client";
import { CustomFieldType } from "@features/custom-fields/types";
import { CustomMatrixApiClient } from "@features/custom-matrix/api-client/api-client";
import {
  CustomMatrixType,
  MatrixEntryType,
} from "@features/custom-matrix/types";
import { DocumentsLabelsApiClient } from "@features/documents-labels/api-client/api-client";
import { DocumentLabelType } from "@features/documents-labels/types";
import { RiskApiClient } from "@features/risk-decisions/api-client/api-client";
import { RiskFactorElement } from "@features/risk-decisions/types";
import { ScenariosApiClient } from "@features/scenarios/api-client/api-client";
import { ScenarioListType, ScenarioType } from "@features/scenarios/types";
import { SessionsLabelsApiClient } from "@features/sessions-labels/api-client/api-client";
import { SessionLabelType } from "@features/sessions-labels/types";
import { useState } from "react";

type ExportType = {
  users: AgentType[];
  scan_filters: PostFilteringListType;
  risk_matrices: { data: MatrixEntryType[]; matrix: CustomMatrixType }[];
  risk_factors: RiskFactorElement[];
  vid_scenarios: { scenario: ScenarioListType; content: ScenarioType }[];
  vid_statuses: SessionLabelType[];
  custom_fields: CustomFieldType[];
  document_categories: DocumentLabelType[];
};

export const ExportImport = () => {
  const [loading, setLoading] = useState(false);
  const [exported, setExported] = useState({
    users: true,
    scan_filters: true,
    risk_matrices: true,
    risk_factors: true,
    vid_scenarios: true,
    vid_statuses: true,
    custom_fields: true,
    document_categories: true,
  });

  const exportConfiguration = async () => {
    setLoading(true);

    const exportData: Partial<ExportType> = {};

    if (exported.users) {
      exportData.users = await AgentsApiClient.getAgents();
    }

    if (exported.scan_filters) {
      exportData.scan_filters = await AlertsFiltersApiClient.get();
    }

    if (exported.risk_matrices) {
      exportData.risk_matrices = [];
      const mtxs = await CustomMatrixApiClient.getMatrices();
      for (const matrix of mtxs) {
        exportData.risk_matrices.push({
          matrix,
          data: await CustomMatrixApiClient.getMatrix(matrix.id),
        });
      }
    }

    if (exported.risk_factors) {
      exportData.risk_factors = await RiskApiClient.getRiskFactors("customer");
    }

    if (exported.vid_scenarios) {
      exportData.vid_scenarios = [];
      const scenarios = await ScenariosApiClient.getScenarios();
      for (const scenario of scenarios.data) {
        exportData.vid_scenarios.push({
          scenario,
          content: await ScenariosApiClient.getScenario(scenario.id),
        });
      }
    }

    if (exported.vid_statuses) {
      exportData.vid_statuses = await SessionsLabelsApiClient.get();
    }

    if (exported.custom_fields) {
      exportData.custom_fields = await CustomFieldsApiClient.get();
    }

    if (exported.document_categories) {
      exportData.document_categories = await DocumentsLabelsApiClient.get();
    }

    const dataStr =
        "data:text/json;charset=utf-8," +
        encodeURIComponent(JSON.stringify(exportData)),
      downloadAnchorNode = document.createElement("a");
    downloadAnchorNode.setAttribute("href", dataStr);
    downloadAnchorNode.setAttribute("download", "export.json");
    document.body.appendChild(downloadAnchorNode); // required for firefox
    downloadAnchorNode.click();
    downloadAnchorNode.remove();

    setLoading(false);
  };

  return (
    <div className="w-full p-4 border border-gray-200 rounded-sm bg-white">
      <Checkbox
        label="Go!Scan filters"
        value={exported.scan_filters}
        onChange={(e) => {
          setExported({ ...exported, scan_filters: e });
        }}
      />
      <br />
      <Checkbox
        label="Go!Risk matrices"
        value={exported.risk_matrices}
        onChange={(e) => {
          setExported({ ...exported, risk_matrices: e });
        }}
      />
      <br />
      <Checkbox
        label="Go!Risk factors"
        value={exported.risk_factors}
        onChange={(e) => {
          setExported({ ...exported, risk_factors: e });
        }}
      />
      <br />
      <Checkbox
        label="Go!Vid scenarios"
        value={exported.vid_scenarios}
        onChange={(e) => {
          setExported({ ...exported, vid_scenarios: e });
        }}
      />
      <br />
      <Checkbox
        label="Go!Vid statuses"
        value={exported.vid_statuses}
        onChange={(e) => {
          setExported({ ...exported, vid_statuses: e });
        }}
      />
      <br />
      <Checkbox
        label="Users"
        value={exported.users}
        onChange={(e) => {
          setExported({ ...exported, users: e });
        }}
      />
      <br />
      <Checkbox
        label="Custom fields"
        value={exported.custom_fields}
        onChange={(e) => {
          setExported({ ...exported, custom_fields: e });
        }}
      />
      <br />
      <Checkbox
        label="Document categories"
        value={exported.document_categories}
        onChange={(e) => {
          setExported({ ...exported, document_categories: e });
        }}
      />
      <br />
      <Button loading={loading} onClick={() => exportConfiguration()}>
        Export
      </Button>
    </div>
  );
};
