import { useGlobalEffect } from "@features/utils";
import { LoadingAtom } from "@features/utils/loading";
import { useCallback } from "react";
import { useRecoilState } from "recoil";
import { CustomMatrixApiClient } from "../api-client/api-client";
import { CustomMatrixEntryAtom } from "./store";
import { MatrixEntryType } from "../types";

export const useMatrixEntries = (id: string) => {
  const [matrix, setMatrix] = useRecoilState(CustomMatrixEntryAtom);
  const [loading, setLoading] = useRecoilState(
    LoadingAtom("useCustomMatrix" + id)
  );

  const refresh = useCallback(async () => {
    if (id === "") return;
    setLoading(true);
    setMatrix(await CustomMatrixApiClient.getMatrix(id));
    setLoading(false);
  }, [setLoading, setMatrix, id]);

  const createMatrixEntry = async (
    entry: MatrixEntryType,
    autoRefresh = true
  ) => {
    try {
      await CustomMatrixApiClient.createMatrixEntry(id, entry);
      if (autoRefresh) refresh();
    } catch (error) {
      console.log("error creating matrix entry", error);
    }
  };

  const deleteMatrixEntry = async (
    entry: MatrixEntryType,
    autoRefresh = true
  ) => {
    try {
      await CustomMatrixApiClient.deleteMatrixEntry(id, entry.id || "unknown");
      if (autoRefresh) refresh();
    } catch (error) {
      console.log("error deleting matrix entry", error);
    }
  };

  const editMatrixEntry = async (
    entry: MatrixEntryType,
    autoRefresh = true
  ) => {
    try {
      await CustomMatrixApiClient.editMatrixEntry(id, entry);
      if (autoRefresh) refresh();
    } catch (error) {
      console.log("error editing matrix entry", error);
    }
  };

  useGlobalEffect(
    "useCustomFields",
    () => {
      refresh();
    },
    []
  );

  return {
    matrix,
    createMatrixEntry,
    editMatrixEntry,
    deleteMatrixEntry,
    loading,
    refresh,
  };
};
