import { Tag } from "@atoms/badge/tag";
import { Button } from "@atoms/button/button";
import Link from "@atoms/link";
import { LinkConfirm } from "@atoms/link/confirm";
import { ROUTES } from "@features/routes";
import { useSession } from "@features/sessions/state/use-sessions";
import { useEffect } from "react";

export const TextWithLinks = (props: { text: string }) => {
  const urlRegex = /(https?:\/\/[^\s]+)/g;
  const chunks = props.text.split(urlRegex).filter((a) => a); //Split text in list of type [str, link, str, link, str]
  return (
    <div>
      {chunks.map((str, i) =>
        i % 2 === 0 ? str : <CustomLinkComponent key={i} url={str} />
      )}
    </div>
  );
};

const CustomLinkComponent = (props: { url: string }) => {
  let sessionId = null;
  if (
    (sessionId = props.url.match(/https?:\/\/[^\s]+?\?session_id=([^\s&]+)/))
  ) {
    return <SessionLinkComponent sessionId={sessionId[1]} />;
  }

  return <LinkConfirm href={props.url}>{props.url}</LinkConfirm>;
};

const SessionLinkComponent = ({ sessionId }: { sessionId: string }) => {
  const { session, refresh } = useSession(sessionId);
  useEffect(() => {
    refresh();
  }, [refresh]);

  const lastDecision = session?.decisions?.[0];

  return (
    <Link to={ROUTES.SessionView.replace(":id", sessionId)}>
      <Button size="sm" theme="outlined">
        <Tag
          className={
            "-ml-2 mr-2 text-white " +
            (lastDecision
              ? lastDecision.type === "NEGATIVE"
                ? "bg-red-500"
                : lastDecision.type === "POSITIVE"
                ? "bg-green-500"
                : "bg-yellow-500"
              : "bg-slate-500")
          }
          noColor
        >
          {session?.session.state === "completed"
            ? lastDecision?.label
            : "in progress"}
        </Tag>{" "}
        Open session
      </Button>
    </Link>
  );
};
