import {
  Question,
  QuestionUpdate,
  ScenarioType,
  ScenarioUpdateType,
} from "./types";

export const SCENARIOS_LANGUAGES: {
  [key: string]: string;
} = {
  "ar-AE": "Arabic",
  "cs-CZ": "Czech",
  "da-DK": "Danish",
  "de-DE": "Deutsch",
  "en-US": "English",
  "es-ES": "Español",
  "fi-FI": "Finnish",
  "fr-FR": "Français",
  "id-ID": "Indonesian",
  "it-IT": "Italian",
  "nl-NL": "Nederlands",
  "ro-RO": "Romanian",
  "ru-RU": "Russian",
  "sv-SE": "Swedish",
  "tr-TR": "Turkish",
};

export const QUESTION_TYPES = [
  "information",
  // "verification",
  "identity",
  "face",
  // "antibot",
  "file_upload",
];

export const SCENARIOS_SCORING_SEVERITY: { [key: string]: string } = {
  eliminatory: "Eliminatory",
  flag: "Flag in scoring",
  consider: "Consider in scoring",
  nothing: "Ignore",
};

export const SCENARIOS_SCORING_FACE_CONTROLS: { [key: string]: string } = {
  "face.imageIntegrity": "Face Image Integrity",
  "face.imageIntegrity.quality": "Face Image Quality",
  "face.imageIntegrity.liveness": "Face Image Liveness",
  "face.personOfInterest.detail": "Face Person of Interest",
  "face.matched": "Face Matched",
  "face.detected": "Face Detected",
};

export const QUESTION_UPLOAD_FILE_MODELS: { [key: string]: string } = {
  hu_address_card: "Hungary Address Card",
};

export const QUESTION_UPLOAD_FILE_FRAME_TYPES: { [key: string]: string } = {
  noframe: "Full screen / No Frame",
  "32frame": "3:2 Frame",
};

export const getAvailableLanguages = (scenario: ScenarioType) => {
  return scenario.questions?.at(0)?.description_localized
    ? Object.keys(scenario.questions.at(0)!.description_localized)
    : [scenario.language];
};

export const transformScenarioUpdate = (input: ScenarioType) => {
  const update = { ...input, questions: [] } as ScenarioUpdateType;
  update.questions = input.questions.map((question) =>
    transformQuestionUpdate(question, input.languages)
  );

  return {
    ...update,
  } as ScenarioUpdateType;
};

export const transformQuestionUpdate = (
  question: Question,
  languagues: string[]
) => {
  const qUpdate = { ...question, description_localized: [] } as QuestionUpdate;
  qUpdate.description_localized = languagues.map((language) => ({
    language,
    description: question.description_localized[language] ?? "",
  }));

  if (question.type === "information") {
    qUpdate.information_data = {
      possible_answers: question.possible_answers,
      should_not_contain: question.should_not_contain,
    };
  }

  if (question.type === "face") {
    qUpdate.face_data = {
      face_number: question.face_number,
    };
  }

  if (question.type === "file_upload") {
    qUpdate.upload_data = {
      max_pages: question.max_pages,
      frame_type: question.frame_type,
      extract_models: question.extract_models,
    };
  }
  return qUpdate;
};
