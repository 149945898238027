import { SessionListType } from "./types";

export const SESSIONS_LANGUAGES: {
  [key: string]: string;
} = {
  "ar-AE": "Arabic",
  "cs-CZ": "Czech",
  "da-DK": "Danish",
  "de-DE": "Deutsch",
  "en-US": "English",
  "es-ES": "Español",
  "fi-FI": "Finnish",
  "fr-FR": "Français",
  "id-ID": "Indonesian",
  "it-IT": "Italian",
  "nl-NL": "Nederlands",
  "ro-RO": "Romanian",
  "ru-RU": "Russian",
  "sv-SE": "Swedish",
  "tr-TR": "Turkish",
};

export const LABEL_TYPE_COLORS = {
  POSITIVE: "bg-green-500",
  NEGATIVE: "bg-red-500",
  NEUTRAL: "bg-slate-500",
  UNDEFINED: "bg-gray-500",
};

export const LABEL_TYPE_COLORS_HEX = {
  POSITIVE: "#10B981",
  NEGATIVE: "#EF4444",
  NEUTRAL: "#FCD34D",
  UNDEFINED: "#6B7280",
};

export const REVIEW_STATUS = ["PASS", "REJECTED"];

export enum SessionWarnings {
  ip_mismatch = 1,
  missing_id_checks,
  watch_list,
  face_fail,
  id_fail,
}

export const extractCustomerSessionFullName = (item: SessionListType) => {
  return [item.customer_firstname, item.customer_lastname]
    .filter((a) => a)
    .join(" ");
};
