import { Checkbox } from "@atoms/input/input-checkbox";
import { InputLabel } from "@atoms/input/input-decoration-label";
import { Input } from "@atoms/input/input-text";
import { SectionSmall } from "@atoms/text";
import { useCustomFields } from "@features/custom-fields/state/use-custom-fields";
import { UpdateCustomersRequest } from "@features/customers/types";
import { SwatchIcon } from "@heroicons/react/24/outline";
import _ from "lodash";

export const CustomerCustomFields = ({
  form,
  setForm,
}: {
  form: UpdateCustomersRequest;
  setForm: (form: UpdateCustomersRequest) => void;
}) => {
  const { fields } = useCustomFields();
  const additionnalFields = fields.filter((field) => field.field_source === 3);

  return (
    <div id="customFields" className="mt-6">
      <div className="flex flex-row justify-between">
        <SectionSmall>Custom fields</SectionSmall>
        <SwatchIcon className="w-5 opacity-50 inline-block mr-1" />
      </div>
      {additionnalFields.map((field) => (
        <InputLabel
          key={field.field_id}
          className="mt-4"
          label={_.capitalize(field.label)}
          input={
            <>
              {field.field_type === 3 && (
                <Checkbox
                  label={_.capitalize(field.label)}
                  value={
                    form.customers[0].fields[field.label] === "true" ||
                    form.customers[0].fields[field.label] === "1" ||
                    (form.customers[0].fields[field.label] as any) === true
                  }
                  onChange={(e) => {
                    setForm({
                      ...form,
                      customers: [
                        {
                          ...form.customers[0],
                          fields: {
                            ...form.customers[0].fields,
                            [field.label]: e ? "true" : "false",
                          },
                        },
                      ],
                    });
                  }}
                />
              )}
              {field.field_type !== 3 && (
                <Input
                  type={field.field_type !== 4 ? "number" : "text"}
                  placeholder={_.capitalize(field.label)}
                  value={form.customers[0].fields[field.label]}
                  onChange={(e) => {
                    setForm({
                      ...form,
                      customers: [
                        {
                          ...form.customers[0],
                          fields: {
                            ...form.customers[0].fields,
                            [field.label]: e.target.value,
                          },
                        },
                      ],
                    });
                  }}
                />
              )}
            </>
          }
        />
      ))}
    </div>
  );
};
