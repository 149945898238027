import { atom } from "recoil";
import {
  ChatMonitoringType,
  KytMonitoringType,
  MonitoringMetricsByDate,
  OnboardingMonitoringType,
  RiskMonitoringType,
  ScanMonitoringType,
} from "../types";

export const OnboardingMonitoringAtom = atom<OnboardingMonitoringType | null>({
  key: "OnboardingMonitoringAtom",
  default: null,
});

export const RiskMonitoringAtom = atom<RiskMonitoringType | null>({
  key: "RiskMonitoringAtom",
  default: null,
});

export const ScanMonitoringAtom = atom<ScanMonitoringType | null>({
  key: "ScanMonitoringAtom",
  default: null,
});

export const ChatMonitoringAtom = atom<ChatMonitoringType | null>({
  key: "ChatMonitoringAtom",
  default: null,
});

export const KytMonitoringAtom = atom<KytMonitoringType | null>({
  key: "KytMonitoringAtom",
  default: null,
});

export const MonitoringTimeseriesAtom = atom<MonitoringMetricsByDate>({
  key: "MonitoringTimeseriesAtom",
  default: [],
});

export const MonitoringTimeseriesPeriodAtom = atom<
  [number, number | undefined]
>({
  key: "MonitoringTimeseriesPeriodAtom",
  default: [0, undefined],
});
