import { ButtonConfirm } from "@atoms/button/confirm";
import { Table } from "@components/table";
import { WebAuthnCredential } from "@features/webauthn/types";
import { formatTime } from "@features/utils/dates";
type AuthenticationDevicesProps = {
  data: WebAuthnCredential[];
  loading: boolean;
  onDelete: (credentialId: number) => void;
};

export function AuthenticationDevices({
  data,
  onDelete,
  loading,
}: AuthenticationDevicesProps) {
  return (
    <Table
      loading={loading}
      showPagination={false}
      data={data}
      columns={[
        {
          title: "IP Address",
          render: (item) => (
            <div className="flex items-center">{item.last_ip_address}</div>
          ),
        },
        {
          title: "First sign in",
          render: (item) => formatTime(item.created_at),
        },
        {
          title: "Last sign in",
          render: (item) => formatTime(item.last_sign_in),
        },
        {
          title: "",
          className: "justify-end",
          render: (item) => (
            <ButtonConfirm
              theme="danger"
              confirmButtonTheme="danger"
              size="sm"
              onClick={async () => {
                onDelete(item.id);
              }}
            >
              Delete device
            </ButtonConfirm>
          ),
        },
      ]}
    />
  );
}
