import { DropdownButton } from "@atoms/dropdown";
import { UpdateMultipleAlertsModalAtom } from "@components/bulk-alert-status";
import {
  Bars4Icon,
  CogIcon,
  PaperAirplaneIcon,
} from "@heroicons/react/24/outline";
import { InboxModalAtom } from "@views/client/inbox/modal";
import { Link, useParams } from "react-router-dom";
import { useSetRecoilState } from "recoil";
import { useAlert } from "../../../../features/alerts/state/use-alerts";
import { useHasAccess } from "../../../../features/auth/state/use-access";
import { ROUTES } from "../../../../features/routes";

export const Menu = () => {
  const { id } = useParams<{ id: string }>();
  const hasAccess = useHasAccess();
  const { alert } = useAlert(id || "");

  const setOpen = useSetRecoilState(UpdateMultipleAlertsModalAtom);
  const openCustomerInbox = useSetRecoilState(InboxModalAtom);

  return (
    <DropdownButton
      options={[
        ...(hasAccess("CHAT")
          ? [
              {
                onClick: () => {
                  openCustomerInbox(alert?.customer_id || null);
                },
                icon: (p: any) => <PaperAirplaneIcon {...p} />,
                label: "Customer inbox",
              },
            ]
          : []),
        ...(alert?.customer_id
          ? [
              {
                icon: (p: any) => <Bars4Icon {...p} />,
                label: (
                  <Link
                    to={ROUTES.CustomerView.replace(":id", alert?.customer_id)}
                    className="flex items-center p-2"
                  >
                    Go to customer
                  </Link>
                ),
              },
            ]
          : []),
        ...(hasAccess("POST_FILTERING_UPDATE")
          ? [
              {
                onClick: () =>
                  setOpen({
                    open: true,
                    customer_id: alert?.customer_external_customer_id || "",
                  }),
                icon: (p: any) => <CogIcon {...p} />,
                label: "Bulk update",
              },
            ]
          : []),
      ]}
      theme="default"
      size="sm"
      className="float-right mt-4"
    >
      Menu
    </DropdownButton>
  );
};
