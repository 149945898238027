import { FullScreenPage } from "@atoms/layout/page";
import Assignment from "@components/assignations";
import { useSession } from "@features/sessions/state/use-sessions";
import { Iframe } from "@views/client";
import { useEffect } from "react";
import { useParams } from "react-router-dom";

export default function SessionDetailsPage() {
  const { id } = useParams();
  const { session, refresh } = useSession(id || "");

  useEffect(() => {
    refresh();
  }, [id, refresh]);

  return (
    <FullScreenPage>
      <div className="flex flex-row h-full">
        {false && (
          <div className="w-1/6 m-4">
            <Assignment
              id={id!}
              type="session"
              groups={session?.review_groups || []}
            />
          </div>
        )}
        <div className="grow h-full">
          <Iframe />;
        </div>
      </div>
    </FullScreenPage>
  );
}
