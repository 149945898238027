import { Button } from "@atoms/button/button";
import { FullScreenPage } from "@atoms/layout/page";
import Link from "@atoms/link";
import { Info, Title } from "@atoms/text";
import RiskFactorsView from "@components/risks";
import { useHasAccess } from "@features/auth/state/use-access";
import { useRiskElement } from "@features/risk-decisions/user-risk-element";
import { ROUTES } from "@features/routes";
import { useEffect } from "react";
import toast from "react-hot-toast";

export const PressDecisionTreePage = () => {
  const hasAccess = useHasAccess();
  const {
    element: decisionTree,
    loading,
    update,
  } = useRiskElement("press", "-1");

  const createDecisionTree = async () => {
    try {
      await update({
        id: 0,
        label: "Press Decision Tree",
        weight: 0,
        type: "customer",
        use_weight: true,
        tree_data: {
          start_node_id: "press_root",
          nodes: [
            {
              id: "press_root",
              name: "Do not trigger",
              leaf: {
                outputs: {
                  trigger: "false",
                },
              },
              output_node_ids: {},
            },
          ],
        },
      });
    } catch (error) {
      toast.error("Error creating decision tree");
      console.log("Error creating decision tree", error);
    }
  };

  useEffect(() => {
    if (!decisionTree && !loading) {
      createDecisionTree();
    }
  }, []);

  return (
    <FullScreenPage>
      <div className="h-full w-full flex flex-col">
        <div className="flex flex-col w-full mx-auto text-black dark:text-white">
          <div className="p-4 pb-2 pt-6">
            <Link to={ROUTES.PressSettings}>
              <Button theme="outlined" className="float-right" size="sm">
                Back to settings
              </Button>
            </Link>
            <Title>Press report selector</Title>
            <Info>
              You can configure which customers will get an automated report or
              not.
            </Info>
          </div>
        </div>
        <div className="grow ">
          {decisionTree && !loading && (
            <RiskFactorsView
              editable={hasAccess("PRESS_MANAGE")}
              type={"press"}
            />
          )}
        </div>
      </div>
    </FullScreenPage>
  );
};
