import { ButtonConfirm } from "@atoms/button/confirm";
import { ModalContent } from "@atoms/modal/modal";
import { SideModal } from "@atoms/modal/side-modal";
import { useCustomFields } from "@features/custom-fields/state/use-custom-fields";
import {
  CustomerDetailType,
  UpdateCustomersRequest,
} from "@features/customers/types";
import { useControlledEffect } from "@features/utils";
import _ from "lodash";
import { useState } from "react";
import { atom, useRecoilState } from "recoil";
import { CustomerComputedFields } from "./customer-computed-fields";
import { CustomerCustomFields } from "./customer-custom-fields";
import { CustomerIdentification } from "./customer-identification";
import { CustomerIdentity } from "./customer-identity";
import Namesearch from "./namesearch";
import { TestAndSave } from "./test-and-save";

const PREFIX_ID = "manual_record_";

export const CustomerEditionModalAtom = atom({
  key: "CustomerEditionModalAtom",
  default: {
    open: false,
    customer: undefined as CustomerDetailType | undefined,
  },
});

export default function CustomerSideModal() {
  const [props, setProps] = useRecoilState(CustomerEditionModalAtom);

  return (
    <SideModal
      className="bg-slate-100 dark:bg-slate-900"
      open={props.open}
      onClose={() => {
        setProps({ open: false, customer: undefined });
      }}
    >
      <ModalContent
        title={
          props.customer ? (
            <div className="flex flex-row items-center">
              Edit customer
              <ButtonConfirm
                size="sm"
                theme="default"
                className="ml-4"
                onClick={() => {
                  setProps({
                    ...props,
                    open: false,
                  });
                  setTimeout(() => {
                    setProps({
                      open: true,
                      customer: undefined,
                    });
                  }, 500);
                }}
              >
                Create new instead
              </ButtonConfirm>
            </div>
          ) : (
            "Add new customer"
          )
        }
      >
        <CustomerSideModalContent
          key={props.customer?.customer?.id}
          onClose={() => {
            setProps({
              open: false,
              customer: undefined,
            });
          }}
          customer={props.customer}
        />
      </ModalContent>
    </SideModal>
  );
}

function CustomerSideModalContent(props: {
  customer?: CustomerDetailType;
  onClose: () => void;
}) {
  const { fields, refresh } = useCustomFields();
  const [form, _setForm] = useState<UpdateCustomersRequest>({
    verify_input: true,
    disable_risk_scan: false,
    relations: [],
    customers: [
      {
        account_number: props.customer?.customer.account_number ?? "",
        account_type: props.customer?.customer.account_type === 3 ? "F" : "N",
        company_name: props.customer?.customer.company_name ?? "",
        date_of_birth:
          props.customer?.customer.date_of_birth?.split("T")[0] ?? "",
        domicile_code: props.customer?.customer.domicile_code ?? "",
        external_id:
          props.customer?.customer.external_id ??
          PREFIX_ID +
            (1000 * Date.now() + Math.floor(Math.random() * 1000)).toString(36),
        fields: {
          ..._.mapValues(
            _.keyBy(props.customer?.custom_fields ?? {}, "name"),
            "value"
          ),

          //Computed values
          is_pep: props.customer?.pep.value ? "1" : "0",
          may_be_pep: props.customer?.may_be_pep.value ? "1" : "0",
          is_sanction: props.customer?.se.value ? "1" : "0",
          may_be_sanction: props.customer?.may_be_san.value ? "1" : "0",
          is_tm: props.customer?.tm.value ? "1" : "0",
          is_str: props.customer?.str.value ? "1" : "0",
        },
        first_name: props.customer?.customer.first_name ?? "",
        last_name: props.customer?.customer.last_name ?? "",
        middle_name: props.customer?.customer.middle_name ?? "",
        nationality_code: props.customer?.customer.nationality_code ?? "",
        registration_number: props.customer?.customer.registration_number ?? "",
        trading_name: props.customer?.customer.trading_name ?? "",
        variations: props.customer?.customer.name_variations ?? [],
        contacts: props.customer?.customer.contacts ?? "",
        review_groups:
          (props.customer?.customer.review_groups || []).join(",") ?? "",
      },
    ],
  });

  const setForm = (newForm: UpdateCustomersRequest) => {
    _setForm({ ...newForm, verify_input: true });
  };

  useControlledEffect(() => {
    if (fields.length === 0) {
      refresh();
    } else {
      let fieldsCustomer: {
        [key: string]: string;
      } = {};
      fields.forEach((field) => {
        if (field.field_source === 4 || field.field_source === 3) {
          fieldsCustomer = {
            ...fieldsCustomer,
            [field.label]:
              form.customers[0]?.fields[field.label] ||
              (field.field_type === 4 ? "" : "0"),
          };
        }
      });
      setForm({
        ...form,
        customers: [
          {
            ...form.customers[0],
            account_number: form.customers[0].external_id,
            fields: fieldsCustomer,
          },
        ],
      });
    }
  }, [refresh, fields]);

  return (
    <div style={{ maxWidth: "800px", width: "100vw" }}>
      <CustomerIdentification
        form={form}
        setForm={setForm}
        disabled={!!props.customer?.customer.id}
      />

      <hr className="my-6 -mx-6" />
      <CustomerIdentity form={form} setForm={setForm} />

      {false && ( // This will be replaced by a dedicated computed-field replacement tool
        <>
          <hr className="my-6 -mx-6" />
          <CustomerComputedFields form={form} setForm={setForm} />
        </>
      )}

      <hr className="my-6 -mx-6" />
      <CustomerCustomFields form={form} setForm={setForm} />

      <hr className="my-6 -mx-6" />
      <Namesearch form={form} />

      <hr className="my-6 -mx-6" />
      <TestAndSave
        customerId={props.customer?.customer.id}
        form={form}
        setForm={_setForm}
      />
    </div>
  );
}
