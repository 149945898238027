import { DropdownButton } from "@atoms/dropdown";
import { PaperAirplaneIcon } from "@heroicons/react/24/outline";
import { InboxModalAtom } from "@views/client/inbox/modal";
import { useSetRecoilState } from "recoil";
import { useHasAccess } from "@features/auth/state/use-access";
import { twMerge } from "tailwind-merge";
import _ from "lodash";

export const Menu = ({
  fromId,
  toId,
}: {
  fromId?: string | null;
  toId?: string | null;
}) => {
  const hasAccess = useHasAccess();

  const openCustomerInbox = useSetRecoilState(InboxModalAtom);
  if (!fromId && !toId) {
    return (
      <DropdownButton options={[]} theme="default" size="sm" disabled>
        Menu
      </DropdownButton>
    );
  }
  return (
    <DropdownButton
      options={
        hasAccess("CHAT")
          ? [
              ...(fromId
                ? [
                    {
                      onClick: () => {
                        openCustomerInbox(fromId);
                      },
                      icon: (p: any) => (
                        <PaperAirplaneIcon
                          className={twMerge(
                            "transform rotate-180",
                            p.className
                          )}
                          {..._.omit(p, "className")}
                        />
                      ),
                      label: "From - Customer Inbox",
                    },
                  ]
                : []),
              ...(toId
                ? [
                    {
                      onClick: () => {
                        openCustomerInbox(toId);
                      },
                      icon: (p: any) => <PaperAirplaneIcon {...p} />,
                      label: "To - Customer Inbox",
                    },
                  ]
                : []),
            ]
          : []
      }
      theme="default"
      size="sm"
    >
      Menu
    </DropdownButton>
  );
};
