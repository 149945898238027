import { Button } from "@atoms/button/button";
import { ButtonConfirm } from "@atoms/button/confirm";
import { InputLabel } from "@atoms/input/input-decoration-label";
import { Input } from "@atoms/input/input-text";
import { Modal, ModalContent } from "@atoms/modal/modal";
import Select from "@atoms/select";
import { Info, Section } from "@atoms/text";
import { Table } from "@components/table";
import { Column } from "@components/table/table";
import { useHasAccess } from "@features/auth/state/use-access";
import { FIELD_TYPES } from "@features/custom-fields/enum";
import { useCustomFields } from "@features/custom-fields/state/use-custom-fields";
import { CustomFieldType, FIELDS_NAMES } from "@features/custom-fields/types";
import {
  DocumentTextIcon,
  HashtagIcon,
  LightBulbIcon,
} from "@heroicons/react/24/outline";
import { SwatchIcon, LockClosedIcon } from "@heroicons/react/20/solid";
import _ from "lodash";
import { useEffect, useState } from "react";

export const CustomFieldsTables = () => {
  const { fields, save, loading, refresh } = useCustomFields();
  const [modal, setModal] = useState<
    Partial<CustomFieldType> & { open: boolean }
  >({ open: false });
  const hasAccess = useHasAccess();

  useEffect(() => {
    refresh();
  }, [refresh]);

  const columns: Column<CustomFieldType>[] = [
    {
      title: "Identifier",
      render: (row) => (
        <>
          {[2, 4, 5].includes(row.field_source) && (
            <Info className="flex items-center mr-2">
              <LockClosedIcon className="h-3 w-3 inline-block mr-1" /> Internal
              field
            </Info>
          )}
          {![2, 4, 5].includes(row.field_source) && (
            <Info className="flex items-center mr-2">
              <SwatchIcon className="h-3 w-3 inline-block mr-1" /> Custom field
            </Info>
          )}
          {row.label}
        </>
      ),
    },
    {
      title: "Display name",
      render: (row) => (
        <>
          {FIELDS_NAMES[row.label] ||
            _.capitalize(row.header_name || row.label.replace(/_/gm, " "))}
        </>
      ),
    },
    {
      title: "Type",
      render: (row) => (
        <Info className="flex items-center mr-2">
          {row.field_type === FIELD_TYPES.NUMBER && (
            <>
              <HashtagIcon className="h-4 w-4 inline-block mr-1" /> Number
            </>
          )}
          {row.field_type === FIELD_TYPES.BOOLEAN && (
            <>
              <LightBulbIcon className="h-4 w-4 inline-block mr-1" /> Boolean
            </>
          )}
          {row.field_type === FIELD_TYPES.TEXT && (
            <>
              <DocumentTextIcon className="h-4 w-4 inline-block mr-1" /> Text
            </>
          )}
        </Info>
      ),
    },
    {
      title: "Actions",
      headClassName: "justify-end",
      className: "justify-end h-12",
      thClassName: "w-24",
      render: (row) =>
        ![2, 4, 5].includes(row.field_source) &&
        hasAccess("SIDENAV_IMPORT_SETUP_SAVE") && (
          <Button
            theme="outlined"
            size="sm"
            onClick={() => setModal({ open: true, ...row })}
          >
            Edit
          </Button>
        ),
    },
  ];

  return (
    <>
      <Modal open={modal.open} onClose={() => setModal({ open: false })}>
        <ModalContent title="Add/Edit custom field">
          <InputLabel
            label="Display name"
            input={
              <Input
                placeholder="My Custom Input"
                value={modal.header_name || ""}
                onChange={(e) =>
                  setModal({ ...modal, header_name: e.target.value })
                }
              />
            }
          />

          <InputLabel
            className="mt-4"
            label="Unique identifier"
            input={
              <Input
                placeholder="my_custom_input"
                value={modal.label || ""}
                onChange={(e) =>
                  setModal({
                    ...modal,
                    label: e.target.value
                      .toLocaleLowerCase()
                      .replace(/[^a-z0-9]+/g, "_"),
                  })
                }
              />
            }
          />

          <InputLabel
            className="mt-4"
            label="Type"
            input={
              <Select
                value={`${modal.field_type || 4}`}
                onChange={(e) =>
                  setModal({
                    ...modal,
                    field_type: parseInt(e.target.value) as any,
                  })
                }
              >
                <option value="4">Text</option>
                <option value="2">Number</option>
                <option value="3">Boolean</option>
              </Select>
            }
          />

          <ButtonConfirm
            confirmTitle="Save custom field"
            confirmMessage="This will be applied now and have an effect on all ongoing risk scoring tasks or scanning tasks. if you currently use the CSV customer import, you must update the CSV format accordingly."
            className="mt-6 float-right"
            theme="primary"
            loading={loading}
            disabled={
              !modal.label ||
              !modal.header_name ||
              fields.some(
                (f) => f.field_id !== modal.field_id && f.label === modal.label
              )
            }
            onClick={async () => {
              if (
                await save([
                  ...fields.filter((f) => f.field_id !== modal.field_id),
                  {
                    field_source: modal.field_source || 2,
                    field_type: modal.field_type || 4,
                    header_name: modal.header_name || "",
                    label: modal.label || "",
                  },
                ])
              ) {
                setModal({ ...modal, open: false });
              }
            }}
          >
            Save
          </ButtonConfirm>

          {fields.find((f) => f.field_id === modal.field_id) && (
            <ButtonConfirm
              confirmTitle="Delete custom field"
              confirmMessage="This will be applied now and have an effect on all ongoing risk scoring tasks or scanning tasks."
              className="mt-6"
              theme="danger"
              loading={loading}
              onClick={async () => {
                if (await save(fields.filter((f) => f.label !== modal.label))) {
                  setModal({ ...modal, open: false });
                }
              }}
            >
              Remove
            </ButtonConfirm>
          )}
        </ModalContent>
      </Modal>

      {hasAccess("SIDENAV_IMPORT_SETUP_SAVE") && (
        <Button
          className="float-right"
          size="sm"
          onClick={() =>
            setModal({
              open: true,
              field_source: 3,
            })
          }
        >
          Add entity field
        </Button>
      )}
      <Section>Entity fields</Section>

      <Table
        data={fields.filter(
          (a) =>
            a.field_source === 2 || a.field_source === 4 || a.field_source === 3
        )}
        columns={columns}
        showPagination={false}
        loading={loading}
      />

      <br />

      {hasAccess("SIDENAV_IMPORT_SETUP_SAVE") && (
        <Button
          className="float-right"
          size="sm"
          onClick={() =>
            setModal({
              open: true,
              field_source: 6,
            })
          }
        >
          Add relation field
        </Button>
      )}
      <Section>Relations fields</Section>

      <Table
        data={fields.filter(
          (a) => a.field_source === 6 || a.field_source === 5
        )}
        columns={columns}
        showPagination={false}
        loading={loading}
      />
    </>
  );
};
