import Avatar from "@atoms/avatar/avatar";
import { Tag } from "@atoms/badge/tag";
import { Button } from "@atoms/button/button";
import { Base, InfoSmall, Section } from "@atoms/text";
import { useHasAccess } from "@features/auth/state/use-access";
import { PressReport } from "@features/press/types";
import {
  REPORT_EDD_STATUS_COLORS,
  REPORT_EDD_STATUS_FROM_UINT_BACKEND,
  REPORT_RISK_LEVEL_COLORS,
} from "@features/press/utils";
import { formatTime } from "@features/utils/dates";
import { CodeBracketIcon } from "@heroicons/react/24/outline";
import _ from "lodash";
import { useRecoilState } from "recoil";
import { ReportStatusAndRiskUpdateModalAtom } from "./status-risk-update-modal";

export const ReportTimeline = ({
  disabled,
  revisions,
}: {
  disabled?: boolean;
  revisions: PressReport[];
}) => {
  const [modal, setModal] = useRecoilState(ReportStatusAndRiskUpdateModalAtom);
  const hasAccess = useHasAccess();

  return (
    <>
      <Section className="mt-4 mb-4">
        Status and Risk Levels
        <div className="float-right">
          {hasAccess("PRESS_AGENT") && (
            <Button
              onClick={() =>
                setModal({
                  ...modal,
                  open: true,
                  customer_id: revisions[0].customer_id,
                  status:
                    REPORT_EDD_STATUS_FROM_UINT_BACKEND[revisions[0].edd_state],
                  active_risk_level: revisions[0].active_overall_risk_level,
                })
              }
              className="ml-4"
              theme="outlined"
              size="sm"
              disabled={disabled}
              shortcut={["u"]}
            >
              Update report
            </Button>
          )}
        </div>
      </Section>
      <ol className="relative border-l border-gray-200 dark:border-gray-700 ml-3">
        {_.sortBy(revisions, (d) => -new Date(d.created_at).getTime()).map(
          (revision, index) => (
            <li
              key={index}
              className={
                "mb-2 ml-6 transition-opacity " +
                (index === 0 ? "" : " opacity-75 hover:opacity-100 ")
              }
            >
              <span className="flex absolute -left-3 justify-center items-center w-6 h-6 bg-slate-500 rounded-full">
                {!revision.created_by_agent_name && (
                  <CodeBracketIcon className="h-4 w-4 text-white" />
                )}
                {!!revision.created_by_agent_name && (
                  <Avatar size={6} fallback={revision.created_by_agent_name} />
                )}
              </span>
              {index === 0 && (
                <>
                  <Tag
                    noColor
                    className={
                      " text-white capitalize block bg-" +
                      REPORT_EDD_STATUS_COLORS[
                        REPORT_EDD_STATUS_FROM_UINT_BACKEND[revision.edd_state]
                      ]
                    }
                  >
                    {REPORT_EDD_STATUS_FROM_UINT_BACKEND[revision.edd_state] ||
                      "Unknown"}
                  </Tag>
                  <Tag
                    noColor
                    className={
                      " text-white capitalize block mx-1 bg-" +
                      REPORT_RISK_LEVEL_COLORS[
                        revision.active_overall_risk_level
                      ]
                    }
                  >
                    {"Active: " + revision.active_overall_risk_level ||
                      "Unknown"}
                  </Tag>
                  <Tag
                    noColor
                    className={
                      " text-white capitalize block mx-1 bg-" +
                      REPORT_RISK_LEVEL_COLORS[
                        revision.robot_overall_risk_level
                      ]
                    }
                  >
                    {"Robot: " + revision.robot_overall_risk_level || "Unknown"}
                  </Tag>
                </>
              )}
              {index > 0 && (
                <>
                  <Tag
                    noColor
                    className={
                      " text-white capitalize block bg-opacity-50 bg-" +
                      REPORT_EDD_STATUS_COLORS[
                        REPORT_EDD_STATUS_FROM_UINT_BACKEND[revision.edd_state]
                      ]
                    }
                  >
                    {REPORT_EDD_STATUS_FROM_UINT_BACKEND[revision.edd_state] ||
                      "Unknown"}
                  </Tag>
                  <Tag
                    noColor
                    className={
                      " text-white capitalize block mx-1 bg-" +
                      REPORT_RISK_LEVEL_COLORS[
                        revision.active_overall_risk_level
                      ]
                    }
                  >
                    {"Active: " + revision.active_overall_risk_level ||
                      "Unknown"}
                  </Tag>
                  <Tag
                    noColor
                    className={
                      " text-white capitalize block mx-1 bg-" +
                      REPORT_RISK_LEVEL_COLORS[
                        revision.robot_overall_risk_level
                      ]
                    }
                  >
                    {"Robot: " + revision.robot_overall_risk_level || "Unknown"}
                  </Tag>{" "}
                </>
              )}
              <Base className="ml-2 whitespace-pre-wrap block">
                {revision.comment}
              </Base>
              <InfoSmall className="block mt-1">
                {revision.created_by_agent_name || "System"} •{" "}
                {formatTime(revision.created_at)}
              </InfoSmall>
            </li>
          )
        )}
      </ol>
    </>
  );
};
