import { Page } from "@atoms/layout/page";
import { Section, Title } from "@atoms/text";
import { ExportImport } from "./exportimport";
import { EnableProducts } from "./enable-products";
import { ScheduledAccess } from "./scheduled-access";

export const AdministrationPage = () => {
  return (
    <Page>
      <Title>Administration</Title>
      <div className="mt-4" />

      <Section className="mt-8">Enable products</Section>
      <EnableProducts />

      <Section className="mt-8">Scheduled access</Section>
      <ScheduledAccess />

      <Section className="mt-8">Export / Import configuration</Section>
      <ExportImport />
    </Page>
  );
};
