import Avatar from "@atoms/avatar/avatar";
import { Tag } from "@atoms/badge/tag";
import { Button } from "@atoms/button/button";
import { Base, InfoSmall, Section } from "@atoms/text";
import { useAlertStates } from "@features/alerts/state/use-alert-states";
import { AlertRevisions } from "@features/alerts/types";
import { useHasAccess } from "@features/auth/state/use-access";
import { formatTime } from "@features/utils/dates";
import { CodeBracketIcon } from "@heroicons/react/24/outline";
import _ from "lodash";
import { useRecoilState } from "recoil";
import { AlertStatusUpdateModalAtom } from "./status-update-modal";

export const AlertTimeline = ({
  revisions,
}: {
  revisions: AlertRevisions[];
}) => {
  const { states } = useAlertStates();
  const [modal, setModal] = useRecoilState(AlertStatusUpdateModalAtom);
  const hasAccess = useHasAccess();

  return (
    <>
      <Section className="mt-4 mb-4">
        Status
        {hasAccess("ALERT_CASEMANAGEMENT") && (
          <Button
            onClick={() =>
              setModal({
                ...modal,
                open: true,
                alert_id: revisions[0].alert_id,
              })
            }
            className="float-right"
            theme="outlined"
            size="sm"
            shortcut={["u"]}
          >
            Update status
          </Button>
        )}
      </Section>
      <ol className="relative border-l border-gray-200 dark:border-gray-700 ml-3">
        {_.sortBy(revisions, (d) => -new Date(d.created_at).getTime()).map(
          (revision, index) => (
            <li
              key={index}
              className={
                "mb-2 ml-6 transition-opacity " +
                (index === 0 ? "" : " opacity-75 hover:opacity-100 ")
              }
            >
              <span className="flex absolute -left-3 justify-center items-center w-6 h-6 bg-slate-500 rounded-full">
                {!revision.agent_id && (
                  <CodeBracketIcon className="h-4 w-4 text-white" />
                )}
                {!!revision.agent_id && (
                  <Avatar size={6} fallback={revision.agent_name} />
                )}
              </span>
              {index === 0 && (
                <Tag
                  noColor
                  className="bg-blue-500 text-white capitalize block"
                >
                  {states[parseInt(revision.state)] || "Unknown"}
                </Tag>
              )}
              {index > 0 && (
                <Tag className="capitalize block">
                  {states[parseInt(revision.state)] || "Unknown"}
                </Tag>
              )}
              <Base className="ml-2 whitespace-pre-wrap">
                {revision.comment}
              </Base>
              <InfoSmall className="block mt-1">
                {revision.agent_name || "System"} •{" "}
                {formatTime(revision.created_at)}
              </InfoSmall>
            </li>
          )
        )}
      </ol>
    </>
  );
};
